.pager {
  width: 100%;
  @include respond-to('s') {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    border-top: 1px solid #b8b8b8;
    border-bottom: 1px solid #b8b8b8;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    margin: 0 auto;

    &:last-child {
      margin-left: 10px;
      border-top: none;
    }

    @include respond-to('s') {
      &:last-child {
        margin-left: 10px;
        border-top: 1px solid #b8b8b8;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  &__overline {
    display: block;
    position: relative;
    @include overline;
    margin-bottom: 45px;
    color: var(--color-typography);
    &:before,
    &:after {
      content: ' ';
      width: 27px;
      @include respond-to('m') {
        width: 37px;
      }
      height: 2px;
      font-size: inherit;
      top: calc((1em * 1.2) / 2 - 6px);
      box-shadow: 0 8px 0px 0px black;
      position: absolute;
      background-color: black;
      vertical-align: middle;
    }
    &:before {
      left: -47px;
      @include respond-to('m') {
        left: -57px;
      }
    }
    &:after {
      right: -47px;
      @include respond-to('m') {
        right: -57px;
      }
    }
  }
  &__title {
    @include title;
    max-width: 548px;
    color: var(--color-main);
  }

  &__item:hover .pager__title {
    color: var(--color-secondary);
  }
}
