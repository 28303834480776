/* Base */

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  /*auto-zoom auf iphone ausschalten*/
  -ms-text-size-adjust: none;
  width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  vertical-align: baseline;
  font-family: $sans;
}

body {
  position: relative;
  letter-spacing: 0.025em;
  background-color: $white;
  color: $c_typo_primary;
}

button,
.button {
  border-radius: 40px;
  border: none;
  background: var(--color-main);
  padding: 30px 25px 29px 25px;
  font-family: Jost, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 120%;
  /* identical to box height, or 36px */
  @include respond-to('m') {
    font-size: 22px;
  }
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    color: #fff;
    background-color: var(--color-secondary);
  }

  color: #f2f2f2;
}
section,
.section {
  position: relative;
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  padding: 41px 35px 30px;
  @include respond-to('m') {
    padding: 71px 135px 50px;
  }
}
section,
.section {
  position: relative;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 41px 35px 30px;
  @include respond-to('s') {
    padding: 81px 65px 40px;
  }
  @include respond-to('m') {
    padding: 71px 135px 50px;
  }
}

section.no-padding {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  @include respond-to('m') {
    padding: 0 55px;
  }
  @include respond-to('m') {
    padding: 0 135px;
  }

  video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::-webkit-media-controls {
      display: none !important;
    }
  }
}
