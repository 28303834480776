.markdown {
  font-size: 18px;
  @include respond-to('m') {
    font-size: 22px;
  }

  a {
    font-weight: 400;
    text-transform: none;
  }
  p {
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $sans;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: var(--color-main);
    margin: 1.2em 0 0.5em 0;
  }
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.3em;
    font-weight: bold;
  }
  h4 {
    font-size: 1.2em;
    font-weight: bold;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
  }
  h6 {
    font-size: 0.9em;
    font-weight: bold;
  }
  b,
  strong {
    font-weight: bold;
  }
  ul,
  ol {
    margin: 1em 0;
    padding: 0 0 0 2em;
  }
  ul {
    list-style-type: none;
    /*use padding to move list item from left to right*/
    padding-left: 1.2em;
    @include text;
    & li:before {
      content: '–';
      position: absolute;
      /*change margin to move dash around*/
      margin-left: -1.2em;
    }
  }

  li p:last-child {
    margin: 0;
  }

  @media print {
    * {
      background: transparent !important;
      color: black !important;
      filter: none !important;
      -ms-filter: none !important;
    }
    body {
      font-size: 12pt;
      max-width: 100%;
    }
    a,
    a:visited {
      text-decoration: underline;
    }
    hr {
      height: 1px;
      border: 0;
      border-bottom: 1px solid black;
    }
    a[href]:after {
      content: ' (' attr(href) ')';
    }
    abbr[title]:after {
      content: ' (' attr(title) ')';
    }
    .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
      content: '';
    }
    pre,
    blockquote {
      border: 1px solid #999;
      padding-right: 1em;
      page-break-inside: avoid;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    img {
      max-width: 100% !important;
    }
    @page :left {
      margin: 15mm 20mm 15mm 10mm;
    }
    @page :right {
      margin: 15mm 10mm 15mm 20mm;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
  }
}
