/* jost-regular - latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/jost-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/jost-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/jost-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/jost-v9-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/jost-v9-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/jost-v9-latin-regular.svg#Jost')
      format('svg'); /* Legacy iOS */
}
/* jost-500 - latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/jost-v9-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/jost-v9-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/jost-v9-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/jost-v9-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/jost-v9-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/jost-v9-latin-500.svg#Jost')
      format('svg'); /* Legacy iOS */
}
/* jost-700 - latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/jost-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/jost-v9-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/jost-v9-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/jost-v9-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/jost-v9-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/jost-v9-latin-700.svg#Jost')
      format('svg'); /* Legacy iOS */
}
/* jost-800 - latin */
@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/jost-v9-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('../fonts/jost-v9-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/jost-v9-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/jost-v9-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/jost-v9-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/jost-v9-latin-800.svg#Jost')
      format('svg'); /* Legacy iOS */
}
$sans: 'Jost', sans-serif;
$sans2: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: Georgia, serif;
$mono: Menlo, Courier, monospace;

@mixin title {
  font-family: $sans;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @include respond-to('s') {
    font-size: 36px;
  }
  @include respond-to('m') {
    font-size: 60px;
    letter-spacing: 0.2em;
  }
}
@mixin headline {
  font-family: $sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  /* or 42px */

  text-align: center;

  text-transform: uppercase;

  @include respond-to('m') {
    font-size: 30px;
    letter-spacing: 0.1em;
  }
}
@mixin headline-alt {
  font-family: $sans;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  /* or 48px */

  text-align: center;

  text-transform: uppercase;

  @include respond-to('s') {
    font-size: 30px;
    letter-spacing: 0.2em;
  }
  @include respond-to('m') {
    font-size: 35px;
    letter-spacing: 0.2em;
  }
  @include respond-to('l') {
    font-size: 40px;
    letter-spacing: 0.2em;
  }
}

@mixin text {
  font-family: $sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 31px */
  @include respond-to('s') {
    font-size: 18px;
  }

  @include respond-to('m') {
    font-size: 22px;
    letter-spacing: 0.05em;
  }
}
@mixin text--small {
  font-family: $sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;

  @include respond-to('m') {
    font-size: 16px;
  }
}
@mixin overline {
  font-family: $sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* identical to box height, or 24px */
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  @include respond-to('m') {
    font-size: 20px;
  }
}

@mixin navigation {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 300%;
  /* identical to box height, or 180px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  @include respond-to('s') {
    font-size: 40px;
    letter-spacing: 0.1em;
  }
  @include respond-to('m') {
    font-size: 60px;
    letter-spacing: 0.2em;
  }
}

@mixin dark-on-light {
  -webkit-font-smoothing: antialiased;
}

.dark-on-light {
  @include dark-on-light;
}

@mixin font-light {
  font-weight: 300;
}

@mixin font-regular {
  font-weight: 400;
}

@mixin font-bold {
  font-weight: 500;
}

@mixin slider-indicator {
  font-family: $sans;
  font-weight: 400;
  font-size: 48px;
  line-height: 1;

  @include respond-to('m') {
    font-size: 56px;
    line-height: 1;
  }
  @include respond-to('l') {
    font-size: 64px;
    line-height: 1;
  }
}

a {
  @include text;
  color: var(--color-main);
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    color: var(--color-secondary);
  }
}
p {
  @include text;
}

address {
  @include text;
}
