.entry {
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__main {
    display: flex;
    flex-direction: column;

    @include respond-to('m') {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__overline {
    display: block;
    position: relative;
    @include overline;

    margin-bottom: 2em;
    @include respond-to('m') {
      margin-bottom: 3em;
    }
    color: var(--color-typography);
    &:before,
    &:after {
      content: ' ';
      width: 27px;
      @include respond-to('m') {
        width: 37px;
      }
      height: 2px;
      font-size: inherit;
      top: calc((1em * 1.2) / 2 - 6px);
      box-shadow: 0 8px 0px 0px black;
      position: absolute;
      background-color: black;
      vertical-align: middle;
    }
    &:before {
      left: -47px;
      @include respond-to('m') {
        left: -57px;
      }
    }
    &:after {
      right: -47px;
      @include respond-to('m') {
        right: -57px;
      }
    }
  }
  &__title {
    @include title;
    max-width: 895px;
    color: var(--color-main);
    margin-bottom: 1.75em;
  }
  &__text {
    @include text;
    .break {
      -webkit-column-break-before: always;
      page-break-after: always;
    }
    .strong {
      font-weight: 700;
      text-transform: uppercase;
      color: var(--color-main);
    }
    .highlighted {
      color: var(--color-main);
    }
    & + & {
      margin-top: 1em;
      @include respond-to('m') {
        margin-top: 0;
      }
    }

  }
  .half {
    p + p,
    p + ul,
    p + ol,
    ol + p,
    ol + ol,
    ol + ul,
    ul + ul,
    ul + ol{
      margin-top: 1em;
    }

    @include respond-to('m') {
      flex: 1 1 50%;
      max-width: 575px;

      &:last-child {
        padding-left: 10px;
      }
      &:first-child {
        padding-right: 10px;
      }
    }
  }
  .center-image picture {
    max-width: 100%;
    margin: 0 auto;
  }

  &__list,
  ul,
  ol {
    &:not(.entry__list--numbered) &:not(ol) {
      list-style-type: none;
      & li:before {
        content: '–';
        position: absolute;
        /*change margin to move dash around*/
        margin-left: -1.2em;
      }
    }
    /*use padding to move list item from left to right*/
    padding-left: 1.2em;
    @include text;
  }
  span.canceled {
  }

}
.entry + .schirmherr {
  margin-top: 100px;
}
