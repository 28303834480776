.ranking {
  display: flex;
  flex-direction: column;
  @include respond-to('s') {
    margin-top: 75px;
    flex-direction: row;
    justify-content: space-between;
  }
  @include respond-to('m') {
    margin-top: 85px;
  }
  @include respond-to('l') {
    margin-top: 105px;
  }
  &__row {
    max-width: 575px;
    @include respond-to('s') {
      flex: 1 1 50%;
      &:last-child {
        padding-left: 10px;
      }
      &:first-child {
        padding-right: 10px;
      }
    }
    @include respond-to('m') {
      max-width: 575px;
    }
  }
  &__winner {
    @include respond-to('s') {
      margin-top: 75px;
    }
    @include respond-to('m') {
      margin-top: 85px;
    }
    @include respond-to('l') {
      margin-top: 105px;
    }
  }

  &__cast,
  &__name,
  &__overline,
  footer {
    @include text;
  }

  &__winner {
    margin-top: 55px;
    justify-content: space-between;
  }

  &__name,
  &__overline {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__name {
  }
  &__overline {
    display: block;
    color: var(--color-main);
  }
  &__date {
  }

  &__concert-url {
    @include text--small;

    font-weight: 600;
    border: 2px solid var(--color-main);
    color: black;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 18px 20px 20px 20px;
    font-size: 12px;

    @include respond-to('s') {
      padding: 18px 40px 20px 40px;
    }
    @include respond-to('m') {
      font-size: 14px;
      padding: 18px 30px 20px 30px;
    }
    @include respond-to('l') {
      padding: 18px 75px 20px 75px;
    }
    position: relative;
    &:hover {
      background-color: var(--color-main);
      color: white;
      &:before {
        transition: all 0.5s ease-in-out;
        content: 'Zum Konzert';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-main);
        color: white;
        box-sizing: border-box;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &__concert-text {
    font-style: italic;
  }

  footer {
    margin-top: calc(2em * 1.4);
  }
}
