.highlight {
  width: 100%;
  max-width: 1440px;
  height: 0;
  overflow: visible;
  &__image {
    &:before {
      position: relative;
      background: url('../images/sparda_jazz_awards_illustration.jpg') no-repeat
        scroll 0 0 transparent;
      width: 1440px;
      display: block;
      height: 959px;
      content: ' ';
      transform: rotate(31.67deg);
      z-index: -1;
      left: 90vw;
      top: -140px;
    }
  }
}
