.winner {
  display: block;
  margin-top: 100px;
  &__header {
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    @include respond-to('s') {
      flex-direction: row;
      justify-content: space-between;
    }
    @include respond-to('m') {
      justify-content: space-between;
    }
  }
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include respond-to('m') {
      max-width: 477px;
    }
  }
  &__content {
    @include respond-to('s') {
      max-width: 375px;
      padding-left: 25px;
    }
    @include respond-to('m') {
      max-width: 477px;
    }
  }

  &__overline {
    @include text;
    display: block;
    text-align: center;
    color: var(--color-main);
    font-weight: bold;
    margin-bottom: 12px;
  }
  &__name {
    @include headline-alt;
    color: var(--color-typography);
    @include respond-to('l') {
      max-width: 477px;
    }
    margin-bottom: 42px;
  }
  &__desc {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 140%;
  }
  &__cast {
    color: var(--color-main);
    margin-top: 31px;
  }
  &__button {
    display: inline-block;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    @include respond-to('m') {
      margin-top: 60px;
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #747474;
      &:hover {
        color: var(--color-secondary);
      }
    }
    &:before,
    &:after {
      content: ' ';
      width: 16px;
      @include respond-to('m') {
        width: 27px;
      }
      height: 2px;
      font-size: inherit;
      top: calc(((1em * 1) / 2));
      box-shadow: 0 6px 0px 0px #747474;
      position: absolute;
      background-color: #747474;
      vertical-align: middle;
    }
    &:before {
      left: -3em;
    }
    &:after {
      right: -3em;
    }
  }
}
