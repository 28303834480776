.years {
  width: 100%;
}

.year {
  border-top: 1px solid #b8b8b8;

  padding: 32px 0;
  &:first-child {
    border-top: none;
  }
  &__headline {
    a {
      display: block;
      font-weight: 600;
      font-size: 40px;
      line-height: 120%;
      /* identical to box height, or 48px */

      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;

      span {
        position: relative;
        display: inline-block;
      }

      &:hover span {
        &:before,
        &:after {
          content: ' ';
          width: 27px;
          @include respond-to('m') {
            width: 37px;
          }
          height: 2px;
          font-size: inherit;
          top: calc((1em * 1.2) / 2 - 6px);
          box-shadow: 0 8px 0px 0px black;
          position: absolute;
          background-color: black;
          vertical-align: middle;
        }
        &:before {
          left: -47px;
          @include respond-to('m') {
            left: -57px;
          }
        }
        &:after {
          right: -47px;
          @include respond-to('m') {
            right: -57px;
          }
        }
      }
    }
  }
  &__winners {
    height: 0;
    overflow: hidden;
  }
}
.year--open {
  .year__headline a span {
    &:before,
    &:after {
      content: ' ';
      width: 27px;
      @include respond-to('m') {
        width: 37px;
      }
      height: 2px;
      font-size: inherit;
      top: calc((1em * 1.2) / 2 - 6px);
      box-shadow: 0 8px 0px 0px black;
      position: absolute;
      background-color: black;
      vertical-align: middle;
    }
    &:before {
      left: -47px;
      @include respond-to('m') {
        left: -57px;
      }
    }
    &:after {
      right: -47px;
      @include respond-to('m') {
        right: -57px;
      }
    }
  }
  .year__winners {
    height: auto;
  }
}
