.main-header {
  position: relative;
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  padding: 41px 23px 30px;
  @include respond-to('s') {
    padding: 51px 33px 40px;
  }
  @include respond-to('m') {
    padding: 71px 43px 50px;
  }

  align-items: flex-start;
  a {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;
    svg {
      margin: 0 auto;
      max-width: 150px;
      height: auto;
      @include respond-to('s') {
        max-width: unset;
      }
    }
  }

  .nav-wrapper {
    flex: 0 0 auto;
    position: absolute;
    right: 23px;
    button {
      position: absolute;
      right: 0;
      z-index: 100;
      padding: 10px 9px 7px 9px;
      border-radius: 10px;
      display: block;
      border: none;
      background-color: var(--color-main);
      svg {
        max-width: 24px;
        height: auto;
        @include respond-to('s') {
          max-width: 32px;
        }
        @include respond-to('m') {
          max-width: unset;
        }
      }
      @include respond-to('s') {
        padding: 14px 14px 11px 14px;
        border-radius: 15px;
      }
      @include respond-to('m') {
        padding: 19px 19px 13px 20px;
        border-radius: 20px;
      }
    }
  }
  .nav {
    z-index: 101;
    height: 0;
    overflow: hidden;
    display: block;
    position: absolute;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    left: 0;
    top: 100%;
    background-color: white;
    padding: 0 23px;
    opacity: 0;
    transition: all 0.75s ease;
    @include respond-to('m') {
      padding: 0 43px;
    }
    ul {
      position: relative;
      z-index: 101;
      display: flex;
      flex-direction: column;

      & > li {
        margin: 0 auto;
        a {
          @include navigation;
          text-decoration: none;
          text-align: center;
          position: relative;

          &:before,
          &:after {
            content: ' ';
            opacity: 0;
            width: 1em;
            height: 2px;
            font-size: inherit;
            top: calc((1em * 1.4) - 3px);
            box-shadow: 0 8px 0 0 black;
            position: absolute;
            background-color: black;
            vertical-align: middle;
            transition: all 0.25s ease;
          }
          &:before {
            left: -1.5em;
            @include respond-to('m') {
              left: -2em;
            }
          }
          &:after {
            right: -1.5em;
            @include respond-to('m') {
              right: -2em;
            }
          }
          color: var(--color-main);
          &:hover {
            color: var(--color-secondary);
            &:before,
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .nav.open {
    opacity: 1;
    height: calc(100vh - 185px);
  }
}
