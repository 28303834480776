.jury-member {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  @include respond-to('s') {
    flex-direction: row;
    margin-top: 200px;
  }
  &:first-child {
    margin-top: 50px;
  }
  &:nth-child(2n-1) {
    @include respond-to('m') {
      flex-direction: row-reverse;
    }
  }

  &__image,
  &__content {
    flex: 0 1 50%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to('m') {
      max-width: 476px;
    }
  }

  &__image {
    display: block;
    text-align: center;
    picture {
      margin: 0 auto;
      display: block;
    }
    img {
      max-width: 300px;
      margin: 0 auto;
      @include respond-to('s') {
        max-width: 300px;
      }
      @include respond-to('m') {
        max-width: 350px;
      }
      @include respond-to('l') {
        max-width: 400px;
      }
      height: auto;
    }
  }

  &__name {
    position: relative;
    @include headline-alt;
    color: var(--color-main);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    @include respond-to('s') {
      margin-bottom: 52px;
      margin-top: 188px;
    }
    @include respond-to('m') {
      margin-bottom: 52px;
      margin-top: 188px;
    }
    &:before,
    &:after {
      content: ' ';
      width: 27px;
      @include respond-to('m') {
        width: 37px;
      }
      height: 2px;
      font-size: inherit;
      top: 1em;
      box-shadow: 0 11px 0px 0px black;
      position: absolute;
      background-color: black;
      vertical-align: middle;
    }
    &:before {
      left: -2em;
      @include respond-to('m') {
        left: -2em;
      }
    }
    &:after {
      right: -2em;
      @include respond-to('m') {
        right: -2em;
      }
    }
    &--threeline {
      &:before,
      &:after {
        top: 1.5em;
      }
    }
  }

  &__description {
  }
}
.jury-member--schirmherr {
  margin-top: 0;
}
