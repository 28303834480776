.image-placeholder {
  height: 959px;
  width: 100%;
  display: block;
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  white-space: nowrap;
  border-width: 0;
}
