/* Variables */

/* Colors */
$black: #000;
$white: #fff;

$gray-1: #707070;
$gray-2: #c0c0c0;
$gray-3: #f0f0f0;

$orange-1: rgba(253, 115, 36, 1);
$orange-2: rgba(230, 68, 26, 1);

$brand-1: $orange-1;

$product-1: $brand-1;

/* Element-Colors */
$c_typo_primary: rgba(0, 0, 0, 1);
$c_typo_secondary: $gray-1;

$c_bg_primary: $white;
$c_bg_secondary: $gray-3;

$c_typo_invers: $white;
$c_bg_invers: $black;
$c_bg_hero: $black;
/* Breakpoints */
$breakpoints: (
  's': 640px,
  'm': 960px,
  'l': 1280px,
  'xl': 1680px
);
/* Z-Indexes */
$z-indexes: (
  'modal': 5000,
  'dropdown': 4000,
  'default': 1,
  'below': -1
);

:root {
  --color-main: rgba(253, 115, 36, 1);
  --color-secondary: rgba(230, 68, 26, 1);
  --color-typography: rgba(0, 0, 0, 1);
  --font-rendering: subpixel-antialiased;
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
