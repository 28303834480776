.footer {
  display: block;
  @include respond-to('l') {
    display: flex;
    flex-direction: row;
  }
  align-items: flex-start;

  &__image {
    flex: 1 0 100%;
    margin: 0px auto 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to('l') {
      flex: 0 0 40%;
    }
    max-width: 379px;
  }

  &__main {
    flex: 0 0 100%;
    @include respond-to('m') {
      flex: 0 1 60%;
    }
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
  }

  &__column {
    @include respond-to('s') {
      flex: 0 0 50%;
      &--right {
        padding-left: 10px;
      }
      &--left {
        padding-right: 10px;
      }
    }
  }

  &__text {
    flex: 0 0 100%;
    @include respond-to('m') {
      display: flex;
    }

    p {
      @include respond-to('m') {
        flex: 0 0 50%;
      }
    }

    .right {
      @include respond-to('m') {
        padding-left: 10px;
      }
    }

    .left {
      @include respond-to('m') {
        padding-right: 10px;
      }
    }
  }

  &__nav {
    margin-bottom: 35px;
    @include respond-to('m') {
      margin-bottom: 75px;
    }

    & > li {
      margin-bottom: 15px;
      @include respond-to('m') {
        margin-bottom: 35px;
      }
      display: flex;
      align-items: center;

      svg:first-child {
        margin-right: 35px;
        max-width: 48px;
        @include respond-to('m') {
          max-width: 64px;
        }
      }

      &:first-child {
        margin-bottom: 15px;
        @include respond-to('m') {
          margin-bottom: 49px;
        }
      }
    }

    a:hover > svg {
      color: var(--color-secondary);
    }
  }
}


.footer {
  &-image svg {
    display: block;
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }
  &-grid {
    margin-bottom: 60px;
    gap: 60px;
    display: grid;

    grid-template-columns: repeat(var(--columns), 1fr);
    @include respond-to('s') {
      --columns: 2;
    }
    @include respond-to('l') {
      --columns: 3;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-inline: 1rem;
    border-top: 1px solid $gray-2;
  }
  &-col {
    display: grid;
    justify-content: center;
    width: 100%;
  }
  &-text {
    max-width: 40ch;
    text-align: center;
    line-height: 1.4;
    @include respond-to('l') {
      text-align: left;
    }
  }
  &-hinweis {
    @include respond-to('s') {
      grid-column:  span 2;
    }
    @include respond-to('l') {
      grid-column: span 1;
    }
  }
  &-nav {

    display: flex;
    align-items: center;
    gap: 32px;

    & > li {
      display: flex;
      align-items: center;

      a{
        color: $black;
        text-decoration: none;
        font-weight: 400;
        font-size: 1rem;
        text-transform: uppercase;
        svg {
          color: $orange-1;
        }
        &:hover {
          &, & svg{
            color: $orange-2;
          }
        }
      }
      svg:first-child {
        max-width: 48px;
        @include respond-to('m') {
          max-width: 64px;
        }
      }
    }
  }
}
