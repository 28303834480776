@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideshow {
  max-width: 100%;
  overflow: hidden;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 12px;

    grid-template-areas:
      'two two'
      'one three';
    @include respond-to('m') {
      grid-gap: 26px;
      grid-template-areas:
        'one two'
        'one three';
    }
  }

  &__elem {
    flex: 0 0 auto;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
    }

    &--one {
      grid-area: one;

      .slideshow__image {
        @include respond-to('m') {
          margin-top: 300px;
        }
      }

      img {
        aspect-ratio: 0.739;
      }
    }

    &--two {
      grid-area: two;

      img {
        aspect-ratio: 1.219;
      }
    }

    &--three {
      grid-area: three;
      max-width: 469px;

      img {
        aspect-ratio: 1.257;
      }
    }
  }

  &__image {
    animation-duration: var(--animate-duration);
    animation-delay: var(--animate-delay);
    animation-fill-mode: both;
    border-radius: 15px;
    @include respond-to('m') {
      border-radius: 30px;
    }
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &--left {
      transform: translate3d(3000px, 0, 0) scaleX(3);

      &.slideshow__image--in {
        animation-name: bounceInLeft;
      }
    }

    &--right {
      transform: translate3d(-3000px, 0, 0) scaleX(3);

      &.slideshow__image--in {
        animation-name: bounceInRight;
      }
    }

    &--out {
      animation-name: fadeOut;
    }
  }

  .prepared {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.slideshow__elem--one {
  .slideshow__image--in {
    --animate-delay: 0.5s;
  }
  .slideshow__image--out {
    --animate-duration: 0.5s;
    --animate-delay: 0.7s;
  }
}

.slideshow__elem--two {
  .slideshow__image--in {
    --animate-delay: 0.1s;
  }
  .slideshow__image--out {
    --animate-duration: 0.5s;
    --animate-delay: 0.2s;
  }
}

.slideshow__elem--three {
  .slideshow__image--in {
    --animate-delay: 0.8s;
  }
  .slideshow__image--out {
    --animate-duration: 0.5s;
    --animate-delay: 1.1s;
  }
}
