.tickets {
  margin-top: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tickets .button {
  padding-left: 4rem;
  padding-right: 4rem;
}
